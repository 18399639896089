import React, { useContext, useEffect, useState } from "react";
import { ISunriseProps } from "./index";
import "./Style.css";

import { formatTime, getPrayerTime } from "../Helpers";
import ApplicationContext from "../../context/ApplicationContext";

export const Sunrise: React.FC<ISunriseProps> = (
  props: ISunriseProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();

  const [date, setDate] = useState(new Date());
  const [sunrise, setSunrise] = useState(new Date());

  function setPrayerTimes() {
    const today = getPrayerTime(prayerTimes, date.getDate());
    if (!today) {
      return;
    }

    setSunrise(today.Sunrise);
  }

  useEffect(() => {
    setDate(props.dateTime);
    setPrayerTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  return <>{formatTime(sunrise)}</>;
};
