import React, { useState, useEffect } from "react";

import { IAnalogProps } from "./IAnalogProps";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import "./Style.css";

export const AnalogClock: React.FC<IAnalogProps> = (
  props: IAnalogProps
): JSX.Element => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div>
        <Clock
          value={value}
          size={"66vh"}
          hourHandWidth={20}
          minuteHandWidth={10}
          secondHandWidth={5}
          renderNumbers={true}
        />
      </div>
    </div>
  );
};
