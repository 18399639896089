import axios, { AxiosResponse } from "axios";
import { addDays } from "../components/Helpers";
import { ITimes } from "../types/ITimes";

const api = axios.create({
  baseURL: "https://api.farooqiah.co.uk/api/",
  timeout: 10000,
  headers: { token: "farooqiah@01274" },
});

export const PrayerTimes = (): Promise<ITimes[] | void> => {
  return api
    .get("PrayerTimes", {
      params: {
        token: "farooqiah@01274",
        start: new Date(),
        end: addDays(new Date(), 365),
      },
    })
    .then((response: AxiosResponse<ITimes[]>) => {
      const data: ITimes[] = [];

      response.data.forEach((times: ITimes) => {
        const time: ITimes = {
          Id: times.Id,
          Day: times.Day,
          Month: times.Month,
          MosqueId: times.MosqueId,
          RamadanDay: times.RamadanDay,
          IsRamadanDay: times.IsRamadanDay,
          JumatFajar: new Date(times.JumatFajar),
          JumatZohar: new Date(times.JumatZohar),
          JumatAsar: new Date(times.JumatAsar),
          JumatMaghrib: new Date(times.JumatMaghrib),
          JumatIsha: new Date(times.JumatIsha),
          StartFajar: new Date(times.StartFajar),
          StartZohar: new Date(times.StartZohar),
          StartAsar: new Date(times.StartAsar),
          StartMaghrib: new Date(times.StartMaghrib),
          StartIsha: new Date(times.StartIsha),
          Sunrise: new Date(times.Sunrise),
          Jumaah1: times.Jumaah1 ? new Date(times.Jumaah1) : undefined,
          Jumaah2: times.Jumaah2 ? new Date(times.Jumaah2) : undefined,
          Jumaah3: times.Jumaah3 ? new Date(times.Jumaah3) : undefined,
        };

        data.push(time);
      });

      //const test = JSON.stringify(response.data);
      return data;
    })
    .catch(function (error: any) {
      console.log("api error @ " + new Date());
      console.log(error);
      return;
    });
};
