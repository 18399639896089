import React, { useContext, useEffect, useState } from "react";
import { ITimeRemainingProps } from "./index";
import "./Style.css";
import {
  addDays,
  addMinutes,
  formatTime,
  getPrayerTime,
  PrayerType,
} from "../Helpers";
import ApplicationContext from "../../context/ApplicationContext";

export const TimeRemaining: React.FC<ITimeRemainingProps> = (
  props: ITimeRemainingProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();

  const [date, setDate] = useState(new Date());
  const [remainingTime, setRemainingTime] = useState("");

  function setPrayerTimes() {
    const today = getPrayerTime(prayerTimes, props.dateTime.getDate());
    const tomorrow = getPrayerTime(prayerTimes, addDays(date, 1).getDate());

    if (!today || !tomorrow) {
      return;
    }

    const time = date.getTime();

    if (time < today.JumatFajar.getTime()) {
      timeRemaining(date, today.JumatFajar, PrayerType.Fajar);
    }

    if (
      time > today.JumatFajar.getTime() &&
      time < today.JumatZohar.getTime()
    ) {
      timeRemaining(date, today.JumatZohar, PrayerType.Dhuhr);
    }

    if (time > today.JumatZohar.getTime() && time < today.JumatAsar.getTime()) {
      timeRemaining(date, today.JumatAsar, PrayerType.Asr);
    }

    if (
      time > today.JumatAsar.getTime() &&
      time < today.StartMaghrib.getTime() &&
      today.IsRamadanDay === true
    ) {
      timeRemaining(date, today.StartMaghrib, PrayerType.Maghrib);
    } else if (
      time > today.JumatAsar.getTime() &&
      time < today.JumatMaghrib.getTime()
    ) {
      timeRemaining(date, today.JumatMaghrib, PrayerType.Maghrib);
    }

    if (
      time > today.JumatMaghrib.getTime() &&
      time < today.JumatIsha.getTime()
    ) {
      timeRemaining(date, today.JumatIsha, PrayerType.Isha);
    }

    if (
      time > today.JumatIsha.getTime() &&
      time < tomorrow.JumatFajar.getTime()
    ) {
      timeRemaining(date, tomorrow.JumatFajar, PrayerType.tomorrowFajar);
    }
  }

  function refresh() {
    setDate(props.dateTime);
    setPrayerTimes();
  }

  function timeRemaining(now: Date, nextPrayer: Date, prayerName: PrayerType) {
    now = new Date(now);
    nextPrayer = new Date(nextPrayer);

    var msec = nextPrayer.getTime() - now.getTime();
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    var remainingTime;

    if (hh > 0) remainingTime = hh + "h " + mm + "min";
    else if (mm === 0) {
      remainingTime = ss + " sec";
    } else {
      remainingTime = mm + 1 + " min";
    }

    context.Data.getSiteData().nextPrayerName = prayerName;

    setRemainingTime(remainingTime);
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  return <>{remainingTime}</>;
};
