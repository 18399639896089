import axios, { AxiosResponse } from "axios";
import { IDuas } from "../types/IDuas";

const api = axios.create({
  baseURL: "https://api.farooqiah.co.uk/api/",
  timeout: 10000,
  headers: { token: "farooqiah@01274" },
});

export const dailyDuas = (): Promise<IDuas[] | void> => {
  return api
    .get("Duas", {
      params: {
        token: "farooqiah@01274",
      },
    })
    .then((response: AxiosResponse<IDuas[]>) => {
      const data: IDuas[] = [];

      response.data.forEach((dua: IDuas) => {
        const d: IDuas = {
          Id: dua.Id,
          Day: dua.Day,
          header: dua.header,
          body: dua.body,
          footer: dua.footer,
        };

        data.push(d);
      });

      //const test = JSON.stringify(response.data);
      return data;
    })
    .catch(function (error: any) {
      console.log("api error @ " + new Date());
      console.log(error);
      return;
    });
};
