import { ITimes } from "../types/ITimes";

export function formatTime(now: Date | undefined) {
  if (now === undefined) {
    return "";
  }
  const hour = now.getHours();
  const minute = now.getMinutes();

  const ahours = hour % 12;
  const hours = ahours ? ahours : 12; // the hour '0' should be '12'
  const minutes = minute < 10 ? "0" + minute : minute;
  var strTime = hours + ":" + minutes;
  return strTime;
}

export function formatTimeWithSeconds(now: Date) {
  const hour = now.getHours();
  const minute = now.getMinutes();

  const ahours = hour % 12;
  const hours = ahours ? ahours : 12; // the hour '0' should be '12'
  const minutes = minute < 10 ? "0" + minute : minute;
  var strTime = hours + ":" + minutes;
  return strTime;
}

export function getPrayerTime(data: ITimes[], day: number) {
  return data.find((today) => {
    return today.Day === day;
  });
}

export function getRamdanTime(data: ITimes[]) {
  let ramadan: ITimes[] = [];
  data.forEach((times) => {
    if (times.IsRamadanDay) {
      ramadan.push(times);
    }
  });

  return ramadan;
}

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export enum PrayerType {
  Fajar = "Fajar",
  Dhuhr = "Dhuhr",
  Asr = "Asr",
  Maghrib = "Maghrib",
  Isha = "Isha",
  tomorrowFajar = "Tomorrow fajar ",
  JummahOne = "Jumaah 1",
  JummahTwo = "Jumaah 2",
  JummahThree = "Jumaah 3",
}

export enum SlideType {
  Loop = -1,
  DisplayTime = 0,
  ShowPrayerInProgress = 1,
}

export function makeDate(now: Date) {
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  );
}
