import React, { useContext, useEffect, useState } from "react";
import { getPrayerTime, makeDate } from "../Helpers";
import { IPrayerNotification } from "./IPrayerNotification";
import ApplicationContext from "../../context/ApplicationContext";

export const PrayerNotification: React.FC<IPrayerNotification> = (
  props: IPrayerNotification
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();
  const [date, setDate] = useState(props.dateTime);

  function requireNotification() {
    const today = getPrayerTime(prayerTimes, props.dateTime.getDate());

    if (!today) {
      return;
    }

    const fajarNotification = makeDate(today.JumatFajar);
    const zoharNotification = makeDate(today.JumatZohar);
    const asarNotification = makeDate(today.JumatAsar);
    const startMaghribNotification = makeDate(today.StartMaghrib);
    const maghribNotification = makeDate(today.JumatMaghrib);
    const ishaNotification = makeDate(today.JumatIsha);
    const nowDateTime = makeDate(date);
    const jumaah1Notification = makeDate(
      today.Jumaah1 ? today.Jumaah1 : today.JumatZohar
    );
    const jumaah2Notification = makeDate(
      today.Jumaah2 ? today.Jumaah2 : today.JumatZohar
    );
    const jumaah3Notification = makeDate(
      today.Jumaah3 ? today.Jumaah3 : today.JumatZohar
    );

    if (
      nowDateTime.getTime() === fajarNotification.getTime() ||
      nowDateTime.getTime() === zoharNotification.getTime() ||
      nowDateTime.getTime() === asarNotification.getTime() ||
      (today.IsRamadanDay &&
        nowDateTime.getTime() === startMaghribNotification.getTime()) ||
      nowDateTime.getTime() === maghribNotification.getTime() ||
      nowDateTime.getTime() === ishaNotification.getTime() ||
      nowDateTime.getTime() === jumaah1Notification.getTime() ||
      nowDateTime.getTime() === jumaah2Notification.getTime() ||
      nowDateTime.getTime() === jumaah3Notification.getTime()
    ) {
      let audioUrl = require("../Alarm/bell.ogg");
      let sound = new Audio(audioUrl);
      sound.play();
    }
  }

  useEffect(() => {
    setDate(props.dateTime);
    requireNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  return <></>;
};
