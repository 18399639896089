import React, { useContext, useEffect, useState } from "react";
import { ITodaysProps, TodayTimes } from "./index";
import "./Style.css";

import {
  addDays,
  formatTime,
  getPrayerTime,
  getRamdanTime,
  PrayerType,
} from "../Helpers";
import ApplicationContext from "../../context/ApplicationContext";
import { Container, Tab, Table, Tabs } from "react-bootstrap";

export const DailyMobileView: React.FC<ITodaysProps> = (
  props: ITodaysProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();
  const [date, setDate] = useState(new Date());

  const [startFajar, setStartFajar] = useState(new Date());
  const [startZohar, setStartZohar] = useState(new Date());
  const [startAsar, setStartAsar] = useState(new Date());
  const [startMaghrib, setStartMaghrib] = useState(new Date());
  const [startIsha, setStartIsha] = useState(new Date());

  const [jumatFajar, setJumatFajar] = useState(new Date());
  const [jumatDhuhr, setJumatZohar] = useState(new Date());
  const [jumatAsar, setJumatAsar] = useState(new Date());
  const [jumatMaghrib, setJumatMaghrib] = useState(new Date());
  const [jumatIsha, setJumatIsha] = useState(new Date());
  const [sunrise, setSunrise] = useState(new Date());

  const [remainingTime, setRemainingTime] = useState("");
  const [nextPrayerName, setNextPrayerName] = useState("");

  const [highlightFajar, setHighlightFajar] = useState(false);
  const [highlightDhyhr, setHighlightDhyhr] = useState(false);
  const [highlightAsar, setHighlightAsar] = useState(false);
  const [highlightMaghrib, setHighlightMaghrib] = useState(false);
  const [highlightIsha, setHighlightIsha] = useState(false);

  const [Jumaah, setJumaah] = useState("");

  const today = getPrayerTime(prayerTimes, props.dateTime.getDate());
  const tomorrow = getPrayerTime(prayerTimes, addDays(date, 1).getDate());
  const ramadan = getRamdanTime(prayerTimes);

  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function setPrayerTimes() {
    if (!today || !tomorrow) {
      return;
    }

    setStartFajar(today.StartFajar);
    setJumatFajar(today.JumatFajar);

    setStartZohar(today.StartZohar);
    setJumatZohar(today.JumatZohar);

    setStartAsar(today.StartAsar);
    setJumatAsar(today.JumatAsar);

    setStartMaghrib(today.StartMaghrib);
    setJumatMaghrib(today.JumatMaghrib);

    setStartIsha(today.StartIsha);
    setJumatIsha(today.JumatIsha);

    setSunrise(today.Sunrise);

    let JumaahTimes = "";
    if (today.Jumaah1 !== undefined) {
      JumaahTimes += formatTime(today.Jumaah1);
    }
    if (today.Jumaah2 !== undefined) {
      JumaahTimes += ", " + formatTime(today.Jumaah2);
    }
    if (today.Jumaah3 !== undefined) {
      JumaahTimes += "," + formatTime(today.Jumaah3);
    }

    setJumaah(JumaahTimes);

    const time = date.getTime();

    if (time < today.JumatFajar.getTime()) {
      timeRemaining(date, today.JumatFajar, PrayerType.Fajar);
    }

    if (
      time > today.JumatFajar.getTime() &&
      time < today.JumatZohar.getTime()
    ) {
      timeRemaining(date, today.JumatZohar, PrayerType.Dhuhr);
    }

    if (time > today.JumatZohar.getTime() && time < today.JumatAsar.getTime()) {
      timeRemaining(date, today.JumatAsar, PrayerType.Asr);
    }

    if (
      time > today.JumatAsar.getTime() &&
      time < today.StartMaghrib.getTime() &&
      today.IsRamadanDay === true
    ) {
      timeRemaining(date, today.StartMaghrib, PrayerType.Maghrib);
    } else if (
      time > today.JumatAsar.getTime() &&
      time < today.JumatMaghrib.getTime()
    ) {
      timeRemaining(date, today.JumatMaghrib, PrayerType.Maghrib);
    }

    if (
      time > today.JumatMaghrib.getTime() &&
      time < today.JumatIsha.getTime()
    ) {
      timeRemaining(date, today.JumatIsha, PrayerType.Isha);
    }

    if (
      time > today.JumatIsha.getTime() &&
      time < tomorrow.JumatFajar.getTime()
    ) {
      timeRemaining(date, tomorrow.JumatFajar, PrayerType.tomorrowFajar);
    }

    if (time > today.JumatFajar.getTime()) {
      setStartFajar(tomorrow.StartFajar);
      setJumatFajar(tomorrow.JumatFajar);
    }

    if (time > today.JumatZohar.getTime()) {
      setStartZohar(tomorrow.StartZohar);
      setJumatZohar(tomorrow.JumatZohar);
    }

    if (time > today.JumatAsar.getTime()) {
      setStartAsar(tomorrow.StartAsar);
      setJumatAsar(tomorrow.JumatAsar);
    }

    if (time > today.JumatMaghrib.getTime()) {
      setStartMaghrib(tomorrow.StartMaghrib);
      setJumatMaghrib(tomorrow.JumatMaghrib);
      setSunrise(tomorrow.Sunrise);
    }
    if (time > today.JumatIsha.getTime()) {
      setStartIsha(tomorrow.StartIsha);
      setJumatIsha(tomorrow.JumatIsha);
    }

    var day = days[date.getDay()];

    if (day === "Friday" && typeof today.Jumaah1 !== "undefined") {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time < today.Jumaah1.getTime()
      ) {
        timeRemaining(date, today.Jumaah1, PrayerType.JummahOne);
        setJumatZohar(today.Jumaah1);
      }
    }

    if (
      day === "Friday" &&
      typeof today.Jumaah2 !== "undefined" &&
      typeof today.Jumaah1 !== "undefined"
    ) {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time > today.Jumaah1.getTime() &&
        time < today.Jumaah2.getTime()
      ) {
        timeRemaining(date, today.Jumaah2, PrayerType.JummahTwo);
        setJumatZohar(today.Jumaah2);
      }
    }

    if (
      day === "Friday" &&
      typeof today.Jumaah2 !== "undefined" &&
      typeof today.Jumaah3 !== "undefined"
    ) {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time > today.Jumaah2.getTime() &&
        time < today.Jumaah3.getTime()
      ) {
        timeRemaining(date, today.Jumaah3, PrayerType.JummahThree);
        setJumatZohar(today.Jumaah3);
      }
    }
  }

  function refresh() {
    setDate(props.dateTime);
    setPrayerTimes();
  }

  function highlight(prayer: PrayerType) {
    setHighlightFajar(false);
    setHighlightDhyhr(false);
    setHighlightAsar(false);
    setHighlightMaghrib(false);
    setHighlightIsha(false);

    if (prayer === PrayerType.Fajar) {
      setHighlightFajar(true);
    } else if (prayer === PrayerType.Dhuhr) {
      setHighlightDhyhr(true);
    } else if (prayer === PrayerType.Asr) {
      setHighlightAsar(true);
    } else if (prayer === PrayerType.Maghrib) {
      setHighlightMaghrib(true);
    } else if (prayer === PrayerType.Isha) {
      setHighlightIsha(true);
    } else if (prayer === PrayerType.tomorrowFajar) {
      setHighlightFajar(true);
    }
  }

  function timeRemaining(now: Date, nextPrayer: Date, prayerName: PrayerType) {
    now = new Date(now);
    nextPrayer = new Date(nextPrayer);

    var msec = nextPrayer.getTime() - now.getTime();
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    var remainingTime;

    if (hh > 0) remainingTime = hh + "h " + mm + "min";
    else if (mm === 0) {
      remainingTime = ss + " sec";
    } else {
      remainingTime = mm + 1 + " min";
    }

    setRemainingTime(remainingTime);
    setNextPrayerName(prayerName + " prayer in");
    highlight(prayerName);
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  const highlightRow = {
    backgroundColor: "lightblue",
  };

  function getRowStyle(prayer: number) {
    if (prayer === 1 && highlightFajar) {
      return highlightRow;
    }
    if (prayer === 2 && highlightDhyhr) {
      return highlightRow;
    }
    if (prayer === 3 && highlightAsar) {
      return highlightRow;
    }
    if (prayer === 4 && highlightMaghrib) {
      return highlightRow;
    }
    if (prayer === 5 && highlightIsha) {
      return highlightRow;
    }
    return undefined;
  }

  function getRowStyleForRamadan(date: Date) {
    const dt = new Date();

    if (
      date.getDay() === dt.getDay() &&
      date.getMonth() === dt.getMonth() &&
      date.getFullYear() === dt.getFullYear()
    ) {
      return highlightRow;
    }
  }

  return (
    <>
      <div
        className="container"
        style={{
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          color: "Blue",
          fontSize: "10vw",
          fontWeight: "bold",
          backgroundColor: "white",
          padding: 20,
        }}
      >
        farooqiah
      </div>

      <div
        className="container"
        style={{
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          color: "Blue",
          fontSize: "5vw",
          fontWeight: "bold",
          backgroundColor: "white",
        }}
      >
        {nextPrayerName} <br />
        {remainingTime}
      </div>

      <div
        className="container"
        style={{
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          color: "Blue",
          fontSize: "4vw",
          fontWeight: "bold",
          backgroundColor: "white",
          paddingBottom: 20,
        }}
      >
        Sunrise: {formatTime(sunrise)}
      </div>

      <div className="container">
        <Tabs
          defaultActiveKey="prayerTimes"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="prayerTimes" title="Today">
            <div className="container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Prayer</th>
                    <th>Start</th>
                    <th>Jummat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={getRowStyle(1)}>Fajr</td>
                    <td style={getRowStyle(1)}>{formatTime(startFajar)}</td>
                    <td style={getRowStyle(1)}>{formatTime(jumatFajar)}</td>
                  </tr>
                  <tr>
                    <td style={getRowStyle(2)}>Dhuhr</td>
                    <td style={getRowStyle(2)}>{formatTime(startZohar)}</td>
                    <td style={getRowStyle(2)}>{formatTime(jumatDhuhr)}</td>
                  </tr>
                  <tr>
                    <td style={getRowStyle(3)}>Asr</td>
                    <td style={getRowStyle(3)}>{formatTime(startAsar)}</td>
                    <td style={getRowStyle(3)}>{formatTime(jumatAsar)}</td>
                  </tr>
                  <tr>
                    <td style={getRowStyle(4)}>Maghrib</td>
                    <td style={getRowStyle(4)}>{formatTime(startMaghrib)}</td>
                    <td style={getRowStyle(4)}>{formatTime(jumatMaghrib)}</td>
                  </tr>
                  <tr>
                    <td style={getRowStyle(5)}>Isha</td>
                    <td style={getRowStyle(5)}>{formatTime(startIsha)}</td>
                    <td style={getRowStyle(5)}>{formatTime(jumatIsha)}</td>
                  </tr>
                  <tr>
                    <td>Jummah(s)</td>
                    <td colSpan={2}>{Jumaah}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="tomorrow" title="tomorrow">
            <div className="container">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Prayer</th>
                    <th>Start</th>
                    <th>Jummat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fajr</td>
                    <td>{formatTime(tomorrow?.StartFajar)}</td>
                    <td>{formatTime(tomorrow?.JumatFajar)}</td>
                  </tr>
                  <tr>
                    <td>Dhuhr</td>
                    <td>{formatTime(tomorrow?.StartZohar)}</td>
                    <td>{formatTime(tomorrow?.JumatZohar)}</td>
                  </tr>
                  <tr>
                    <td>Asr</td>
                    <td>{formatTime(tomorrow?.StartAsar)}</td>
                    <td>{formatTime(tomorrow?.JumatAsar)}</td>
                  </tr>
                  <tr>
                    <td>Maghrib</td>
                    <td>{formatTime(tomorrow?.StartMaghrib)}</td>
                    <td>{formatTime(tomorrow?.JumatMaghrib)}</td>
                  </tr>
                  <tr>
                    <td>Isha</td>
                    <td>{formatTime(tomorrow?.StartIsha)}</td>
                    <td>{formatTime(tomorrow?.JumatIsha)}</td>
                  </tr>
                  <tr>
                    <td>Jummah(s)</td>
                    <td colSpan={2}>{Jumaah}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="ramadan" title="Ramadan">
            <Table responsive>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Date</th>
                  <th>Sehar end </th>
                  <th>Iftar</th>
                </tr>
              </thead>
              <tbody>
                {ramadan.map((d) => (
                  <tr>
                    <td style={getRowStyleForRamadan(d?.JumatFajar)}>
                      {d?.RamadanDay}
                    </td>
                    <td style={getRowStyleForRamadan(d?.JumatFajar)}>
                      {d?.StartFajar.toDateString()}
                    </td>
                    <td style={getRowStyleForRamadan(d?.JumatFajar)}>
                      {formatTime(d?.StartFajar)}
                    </td>
                    <td style={getRowStyleForRamadan(d?.JumatFajar)}>
                      {formatTime(d?.StartMaghrib)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <br />
            please visit "farooqiah.co.uk" for comprehensive time table and
            other information
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
