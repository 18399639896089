import React, { useContext } from "react";
import ApplicationContext from "../../context/ApplicationContext";
import "./Style.css";
import { IDuasProps } from "./IDuasProps";

export const Duas: React.FC<IDuasProps> = (props: IDuasProps): JSX.Element => {
  const context = useContext(ApplicationContext);
  const settings = context.Data.getSettings();

  const defaultDua = (): JSX.Element => {
    return (
      <div>
        <p className="duas-header">
          Reliance - Tawakkul <br />
          <br />
          رَبِّ إِنِّى لِمَآ أَنزَلْتَ إِلَىَّ مِنْ خَيْرٍ فَقِيرٌ
          <br />
          <br />
        </p>

        <p className="duas-body">
          Rabbi inni lima anzalta ilayya min khairin faqir
        </p>
        <br />
        <br />
        <p className="duas-body"></p>
      </div>
    );
  };

  const showTodayDua = (): JSX.Element => {
    const dateObj = new Date();
    const day = dateObj.getUTCDate();
    const todayDua = context.Data.getDuas()[day - 1];

    if (todayDua === undefined) return defaultDua();

    return (
      <div
        style={{
          fontSize: "2vw",
          color: "yellow",
          fontWeight: "bolder",
        }}
      >
        {todayDua.header}
        <br />
        <br />
        {todayDua.body}
        <br />
        {todayDua.footer}
      </div>
    );
  };

  const showDataForSide2 = (): JSX.Element => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: settings.Slide3 }} />;
      </div>
    );
  };

  return <div>{settings.ShowDuas ? showTodayDua() : showDataForSide2()}</div>;
};
