import axios, { AxiosResponse } from "axios";
import { ISettings } from "../types/ISettings";

const api = axios.create({
  baseURL: "https://api.farooqiah.co.uk/api/",
  timeout: 10000,
  headers: { token: "farooqiah@01274" },
});

export const ConfigurationService = (): Promise<ISettings | void> => {
  return api
    .get("Info", {
      params: {
        token: "farooqiah@01274",
      },
    })
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(function (error: any) {
      console.log("configuration api error @ " + new Date());
      console.log(error);
      return;
    });
};
