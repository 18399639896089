import React, { useContext, useEffect, useRef, useState } from "react";
import { IDashboardProps } from "./";
import { ITodaysProps, TodayTimes } from "../TodayTimes";
import { PrayerTimes } from "../../services/PrayerTimes";
import { Carousel } from "react-bootstrap";
import "./Style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  IPrayerNotification,
  PrayerNotification,
} from "../PrayerNotification/index";
import loadingImage from "../Images/Loading.gif";
import { DigitalClock, IDigitalProps } from "../Digital";
import { ITimeRemainingProps, TimeRemaining } from "../TimeRemaining";
import { ISunriseProps, Sunrise } from "../Sunrise";
import { IJummaTimesProps, Jumma1, Jumma2 } from "../JummaTimes";
import { DailyMobileView } from "../TodayTimes/DailyMobileView";
import backgroundImage1 from "../Images/backgroundImage.jpg";
import ApplicationContext from "../../context/ApplicationContext";
import { AnalogClock } from "../Analog";
import { PrayerStatus } from "../prayerStatus";
import { ConfigurationService } from "../../services/Configuration";
import { Duas } from "../Duas";
import { dailyDuas } from "../../services/Duas";

export const Dashboard: React.FC<IDashboardProps> = (
  props: IDashboardProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const settings = context.Data.getSettings();

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  let isMobile = window.innerWidth < 768;
  let width = window.innerWidth;
  let height = window.innerHeight;

  async function loadPrayerTimes(silent: boolean) {
    if (!silent) {
      setLoading(true);
    }

    const response = await PrayerTimes();

    if (!response) {
      settings.error = true;
      return;
    }

    if (response.length === 0) {
      settings.error = true;
      return;
    }

    settings.error = false;
    context.Data.setTimes(response);

    if (!silent) {
      setLoading(false);
      setFirstLoad(false);
    }
  }

  async function GetDuas() {
    const response = await dailyDuas();

    if (!response) {
      settings.error = true;
      console.log(response);
      return;
    }
    context.Data.setDuas(response);
  }

  async function GetConfigurations() {
    const response = await ConfigurationService();

    if (!response) {
      settings.error = true;
      console.log(response);
      return;
    }
    context.Data.setSettings(response);
  }

  useEffect(() => {
    loadPrayerTimes(false);
    GetConfigurations();
    GetDuas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstLoad === false) {
      loadPrayerTimes(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.reload]);

  const todaysProps: ITodaysProps = {
    dateTime: props.dateTime,
  };

  const notificationProps: IPrayerNotification = {
    dateTime: props.dateTime,
  };

  const digitalClockProps: IDigitalProps = {
    dateTime: props.dateTime,
  };

  const timeRemainingProps: ITimeRemainingProps = {
    dateTime: props.dateTime,
  };

  const sunriseProps: ISunriseProps = {
    dateTime: props.dateTime,
  };
  const jummaProps: IJummaTimesProps = {
    dateTime: props.dateTime,
  };

  const preparing = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  const notifications = (): JSX.Element => {
    return <PrayerNotification {...notificationProps} />;
  };

  const analogClock = (): JSX.Element => {
    return <AnalogClock {...notificationProps} />;
  };

  const dashboard = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${backgroundImage1})`,
            backgroundSize: "100% 100%",
            gridArea: "1/1",
          }}
        >
          <div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <table style={{ width: "100%" }}>
                <td>{analogClock()}</td>
                <td
                  style={{
                    width: "100%",
                    alignItems: "center",
                    textAlign: "center",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <Carousel
                    controls={false}
                    pause={false}
                    indicators={false}
                    activeIndex={
                      context.Data.getSiteData().slide === -1
                        ? undefined
                        : context.Data.getSiteData().slide
                    }
                    slide={true}
                  >
                    <Carousel.Item interval={5000}>
                      <div>
                        <div>
                          <div
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                              fontSize: "3vw",
                              fontWeight: "bold",
                            }}
                          >
                            {context.Data.getSiteData().nextPrayerName} Prayer
                            in
                          </div>
                          <div
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                              fontSize: "12vw",
                              fontWeight: "bold",
                            }}
                          >
                            <TimeRemaining {...timeRemainingProps} />
                          </div>

                          <div
                            style={{
                              alignItems: "center",
                              textAlign: "center",
                              verticalAlign: "middle",
                              fontWeight: "bold",
                              fontSize: "2vw",
                            }}
                          >
                            Friday Prayer : <Jumma1 {...jummaProps} /> &{" "}
                            <Jumma2 {...jummaProps} />
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                      <div>
                        <PrayerStatus dateTime={props.dateTime} />
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                      <div>
                        <Duas dateTime={props.dateTime} />
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </td>
              </table>
              {/* <table style={{ width: "100%" }}>
              <td
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  verticalAlign: "middle",
                  color: "blue",
                }}
              >
                <div style={highlight}>
                  Prayer in <br />
                  <div
                    style={{
                      fontSize: "7vh",
                      fontWeight: "bold",
                    }}
                  >
                    <TimeRemaining {...timeRemainingProps} />
                  </div>
                </div>
                <br />
                <div style={highlight}>
                  Sunrise <br />
                  <div style={{ fontSize: "5vh", fontWeight: "bold" }}>
                    <Sunrise {...sunriseProps} />
                    am
                  </div>
                </div>
                <br />
                <div style={highlight}>
                  Jummah 1 <br />
                  <div style={{ fontSize: "5vh", fontWeight: "bold" }}>
                    <Jumma1 {...jummaProps} />
                  </div>
                </div>
                <br />
                <div style={highlight}>
                  Jummah 2 <br />
                  <div style={{ fontSize: "5vh", fontWeight: "bold" }}>
                    <Jumma2 {...jummaProps} />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      paddingTop: "20px",
                      color: "wheat",
                    }}
                  >
                    {width}*{height} : v1.2
                  </div>
                </div>
              </td>
              <td
                style={{
                  width: "80%",
                  alignItems: "center",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    display: "flex",
                  }}
                  // ref={componentRef}
                >
                  {" "}
                  <Carousel
                    controls={false}
                    pause={false}
                    indicators={false}
                    activeIndex={context.Data.getSiteData().slide}
                    slide={true}
                  >
                    <Carousel.Item interval={15000}>
                      <div>
                        <div>
                          Prayer in <br />
                          <div>
                            <div>
                              .....{context.Data.getSiteData().nextPrayerName}
                            </div>
                            <TimeRemaining {...timeRemainingProps} />
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={15000}>
                      <div>I am slide two</div>
                    </Carousel.Item>
                    <Carousel.Item interval={15000}>
                      <div>I am slide three</div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </td>
            </table> */}
            </div>
            <div>
              <TodayTimes {...todaysProps} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const mobileView = (): JSX.Element => {
    return (
      <>
        <>
          <DailyMobileView dateTime={props.dateTime} />
        </>
      </>
    );
  };

  const dashboardDecider = (): JSX.Element => {
    return (
      <>
        {isMobile ? (
          loading ? (
            preparing()
          ) : (
            mobileView()
          )
        ) : (
          <div>{loading ? preparing() : dashboard()}</div>
        )}

        <div>{notifications()}</div>
      </>
    );
  };
  return <>{dashboardDecider()}</>;
};
