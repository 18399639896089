import { ISettings } from "../../types/ISettings";
import { IDuas } from "../../types/IDuas";
import { ITimes } from "../../types/ITimes";
import { IData } from "./IData";
import { ISiteData } from "../../types/ISiteData";

const defaultSettings: ISettings = {
  settingsId: 0,
  error: false,
  reload: false,
  retry: false,
  Id: 0,
  Slide2: "",
  Slide3: "",
  ShowDuas: false,
};

const defaultSiteData: ISiteData = {
  nextPrayerName: "",
  slide: undefined,
};

let settings: ISettings = defaultSettings;
let siteDate: ISiteData = defaultSiteData;
let duas: IDuas[] = [];
let times: ITimes[] = [];

export class Data implements IData {
  public setTimes(t: ITimes[]) {
    times = t;
  }
  public getTimes(): ITimes[] {
    return times;
  }

  public setDuas(d: IDuas[]) {
    duas = d;
  }
  public getDuas() {
    return duas;
  }
  public setSettings(s: ISettings) {
    settings = s;
  }
  public getSettings() {
    return settings;
  }

  public setSiteData(data: ISiteData) {
    siteDate = data;
  }
  public getSiteData() {
    return siteDate;
  }
}
