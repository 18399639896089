import React, { useContext, useEffect, useState } from "react";
import { ITodaysProps } from "./index";
import {
  addDays,
  addMinutes,
  formatTime,
  getPrayerTime,
  PrayerType,
} from "../Helpers";

import prayerBg from "../Images/prayerBg.jpg";
import ApplicationContext from "../../context/ApplicationContext";
import { Sunrise } from "../Sunrise";

export const TodayTimes: React.FC<ITodaysProps> = (
  props: ITodaysProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();

  const [date, setDate] = useState(new Date());

  const [startFajar, setStartFajar] = useState(new Date());
  const [startZohar, setStartZohar] = useState(new Date());
  const [startAsar, setStartAsar] = useState(new Date());
  const [startMaghrib, setStartMaghrib] = useState(new Date());
  const [startIsha, setStartIsha] = useState(new Date());

  const [jumatFajar, setJumatFajar] = useState(new Date());
  const [jumatDhuhr, setJumatZohar] = useState(new Date());
  const [jumatAsar, setJumatAsar] = useState(new Date());
  const [jumatMaghrib, setJumatMaghrib] = useState(new Date());
  const [jumatIsha, setJumatIsha] = useState(new Date());
  const [sunrise, setSunrise] = useState(new Date());

  // const [remainingTime, setRemainingTime] = useState("");
  // const [nextPrayerName, setNextPrayerName] = useState("");

  const [highlightFajar, setHighlightFajar] = useState(false);
  const [highlightDhyhr, setHighlightDhyhr] = useState(false);
  const [highlightAsar, setHighlightAsar] = useState(false);
  const [highlightMaghrib, setHighlightMaghrib] = useState(false);
  const [highlightIsha, setHighlightIsha] = useState(false);

  const [showFajar, setShowFajar] = useState(true);
  const [showDhuhr, setShowDhuhr] = useState(true);
  const [showAsar, setShowAsar] = useState(true);
  const [showIsha, setShowIsha] = useState(true);

  const width = window.innerWidth;
  const height = window.innerHeight;

  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function makeDate(now: Date): Date {
    const temp = new Date();
    return new Date(
      temp.getFullYear(),
      temp.getMonth(),
      temp.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );
  }

  function blink(prayer: PrayerType) {
    if (prayer === PrayerType.Fajar) {
      if (showFajar) {
        setShowFajar(false);
      } else {
        setShowFajar(true);
      }
    } else if (prayer === PrayerType.Dhuhr) {
      if (showDhuhr) {
        setShowDhuhr(false);
      } else {
        setShowDhuhr(true);
      }
    } else if (prayer === PrayerType.Asr) {
      if (showAsar) {
        setShowAsar(false);
      } else {
        setShowAsar(true);
      }
    } else if (prayer === PrayerType.Isha) {
      if (showIsha) {
        setShowIsha(false);
      } else {
        setShowIsha(true);
      }
    }
  }

  function showAllTimes() {
    setShowFajar(true);
    setShowDhuhr(true);
    setShowAsar(true);
    setShowIsha(true);
  }

  function requireBlinking(
    todayPrayer: Date,
    tomorrowPrayer: Date,
    prayer: PrayerType
  ) {
    const delay = addMinutes(todayPrayer, 15);

    const t1 = makeDate(todayPrayer);
    const t2 = makeDate(tomorrowPrayer);

    if (t1.getTime() !== t2.getTime()) {
      if (
        date.getTime() > todayPrayer.getTime() &&
        date.getTime() < delay.getTime()
      ) {
        if (prayer === PrayerType.Fajar) {
          blink(PrayerType.Fajar);
        } else if (prayer === PrayerType.Dhuhr) {
          blink(PrayerType.Dhuhr);
        } else if (prayer === PrayerType.Asr) {
          blink(PrayerType.Asr);
        } else if (prayer === PrayerType.Isha) {
          blink(PrayerType.Isha);
        }
      } else {
        showAllTimes();
      }
    } else {
      showAllTimes();
    }
  }

  function setPrayerTimes() {
    const today = getPrayerTime(prayerTimes, props.dateTime.getDate());
    const tomorrow = getPrayerTime(prayerTimes, addDays(date, 1).getDate());

    if (!today || !tomorrow) {
      return;
    }

    setStartFajar(today.StartFajar);
    setJumatFajar(today.JumatFajar);

    setStartZohar(today.StartZohar);
    setJumatZohar(today.JumatZohar);

    setStartAsar(today.StartAsar);
    setJumatAsar(today.JumatAsar);

    setStartMaghrib(today.StartMaghrib);
    setJumatMaghrib(today.JumatMaghrib);

    setStartIsha(today.StartIsha);
    setJumatIsha(today.JumatIsha);

    setSunrise(today.Sunrise);

    const time = date.getTime();

    if (time < today.JumatFajar.getTime()) {
      highlight(PrayerType.Fajar);
    }

    if (
      time > today.JumatFajar.getTime() &&
      time < today.JumatZohar.getTime()
    ) {
      highlight(PrayerType.Dhuhr);
    }

    if (time > today.JumatZohar.getTime() && time < today.JumatAsar.getTime()) {
      highlight(PrayerType.Asr);
    }

    if (
      time > today.JumatAsar.getTime() &&
      time < today.StartMaghrib.getTime() &&
      today.IsRamadanDay === true
    ) {
      highlight(PrayerType.Maghrib);
    } else if (
      time > today.JumatAsar.getTime() &&
      time < today.JumatMaghrib.getTime()
    ) {
      highlight(PrayerType.Maghrib);
    }

    if (
      time > today.JumatMaghrib.getTime() &&
      time < today.JumatIsha.getTime()
    ) {
      highlight(PrayerType.Isha);
    }

    if (
      time > today.JumatIsha.getTime() &&
      time < tomorrow.JumatFajar.getTime()
    ) {
      highlight(PrayerType.tomorrowFajar);
    }

    if (time > today.JumatFajar.getTime()) {
      setStartFajar(tomorrow.StartFajar);
      setJumatFajar(tomorrow.JumatFajar);
      requireBlinking(today.JumatFajar, tomorrow.JumatFajar, PrayerType.Fajar);
    }

    if (time > today.JumatZohar.getTime()) {
      setStartZohar(tomorrow.StartZohar);
      setJumatZohar(tomorrow.JumatZohar);
      requireBlinking(today.JumatZohar, tomorrow.JumatZohar, PrayerType.Dhuhr);
    }

    if (time > today.JumatAsar.getTime()) {
      setStartAsar(tomorrow.StartAsar);
      setJumatAsar(tomorrow.JumatAsar);
      requireBlinking(today.JumatAsar, tomorrow.JumatAsar, PrayerType.Asr);
    }

    if (time > today.JumatMaghrib.getTime()) {
      setStartMaghrib(tomorrow.StartMaghrib);
      setJumatMaghrib(tomorrow.JumatMaghrib);
      setSunrise(tomorrow.Sunrise);
    }
    if (time > today.JumatIsha.getTime()) {
      setStartIsha(tomorrow.StartIsha);
      setJumatIsha(tomorrow.JumatIsha);
      requireBlinking(today.JumatIsha, tomorrow.JumatIsha, PrayerType.Isha);
    }

    var day = days[date.getDay()];

    if (day === "Friday" && typeof today.Jumaah1 !== "undefined") {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time < today.Jumaah1.getTime()
      ) {
        highlight(PrayerType.Dhuhr);
        setJumatZohar(today.Jumaah1);
      }
    }

    if (
      day === "Friday" &&
      typeof today.Jumaah2 !== "undefined" &&
      typeof today.Jumaah1 !== "undefined"
    ) {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time > today.Jumaah1.getTime() &&
        time < today.Jumaah2.getTime()
      ) {
        highlight(PrayerType.Dhuhr);
        setJumatZohar(today.Jumaah2);
      }
    }

    if (
      day === "Friday" &&
      typeof today.Jumaah2 !== "undefined" &&
      typeof today.Jumaah3 !== "undefined"
    ) {
      if (
        time > today.JumatFajar.getTime() &&
        time < today.JumatAsar.getTime() &&
        time > today.Jumaah2.getTime() &&
        time < today.Jumaah3.getTime()
      ) {
        highlight(PrayerType.Dhuhr);
        setJumatZohar(today.Jumaah3);
      }
    }
  }

  function refresh() {
    setDate(props.dateTime);
    setPrayerTimes();
  }

  function highlight(prayer: PrayerType) {
    setHighlightFajar(false);
    setHighlightDhyhr(false);
    setHighlightAsar(false);
    setHighlightMaghrib(false);
    setHighlightIsha(false);

    if (prayer === PrayerType.Fajar) {
      setHighlightFajar(true);
    } else if (prayer === PrayerType.Dhuhr) {
      setHighlightDhyhr(true);
    } else if (prayer === PrayerType.Asr) {
      setHighlightAsar(true);
    } else if (prayer === PrayerType.Maghrib) {
      setHighlightMaghrib(true);
    } else if (prayer === PrayerType.Isha) {
      setHighlightIsha(true);
    } else if (prayer === PrayerType.tomorrowFajar) {
      setHighlightFajar(true);
    }
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  function isHighlighted(prayer: boolean): React.CSSProperties {
    if (prayer) {
      return highlightPrayer;
    } else {
      return {};
    }
  }

  const dt1 = new Date().toLocaleDateString("en-GB");

  const highlightPrayer = {
    borderRadius: "25px",
    backgroundColor: "blue",
    width: "100%",
    height: "100%",
    color: "white",
    fontSize: "3.2vw",
  };

  const tdStyle: React.CSSProperties = {
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    color: "blue",
    fontSize: "4vh",
    borderRight: "1px solid blue",
    width: "15%",
    backgroundColor: "white",
    backgroundImage: `url(${prayerBg})`,
    backgroundSize: "100% 100%",
    fontWeight: "bolder",
  };

  function formatTodayTime() {
    let time = "";
    time += props.dateTime.getHours() + ":";
    time += (date.getMinutes() < 10 ? "0" : "") + date.getMinutes() + ":";
    time += (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();

    return time;
  }

  function getDateAndTime() {
    let date = props.dateTime;

    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Check whether AM or PM
    let newformat = hours >= 12 ? "PM" : "AM";

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    let minutes1 = minutes < 10 ? "0" + minutes : minutes;
    let secs =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

    return hours + ":" + minutes1 + ":" + secs + " " + newformat;
  }

  function getDayOfWeek() {
    var day = days[props.dateTime.getDay()];
    return day;
  }

  return (
    <>
      <table style={{ width: "100%", height: "100%" }}>
        <td style={tdStyle}>
          <div style={{ width: "29vw" }}>
            {getDayOfWeek()} {dt1} <br />
            <div style={{ fontSize: "8vh", width: "29vw" }}>
              {getDateAndTime()}
            </div>
            <div style={{ fontSize: "5vh" }}>
              Sunrise <Sunrise dateTime={props.dateTime} />
            </div>
            {/* <div style={{ fontSize: "5vh" }}>
              {width}x{height}
            </div> */}
          </div>
        </td>

        <td style={tdStyle}>
          <div style={isHighlighted(highlightFajar)}>
            Fajr <br />
            <div style={{ fontSize: "11vh", fontWeight: "bold" }}>
              {showFajar ? formatTime(jumatFajar) : <></>}
            </div>
            <div style={{ fontSize: "5vh" }}>{formatTime(startFajar)}</div>
          </div>
        </td>

        <td style={tdStyle}>
          <div style={isHighlighted(highlightDhyhr)}>
            Zuhar <br />
            <div style={{ fontSize: "11vh", fontWeight: "bold" }}>
              {showDhuhr ? formatTime(jumatDhuhr) : <></>}{" "}
            </div>
            <div style={{ fontSize: "5vh" }}>{formatTime(startZohar)}</div>
          </div>
        </td>
        <td style={tdStyle}>
          <div style={isHighlighted(highlightAsar)}>
            Asar <br />
            <div style={{ fontSize: "11vh", fontWeight: "bold" }}>
              {showAsar ? formatTime(jumatAsar) : <></>}{" "}
            </div>
            <div style={{ fontSize: "5vh" }}>{formatTime(startAsar)}</div>
          </div>
        </td>
        <td style={tdStyle}>
          <div style={isHighlighted(highlightMaghrib)}>
            Maghrib <br />
            <div style={{ fontSize: "11vh", fontWeight: "bold" }}>
              {formatTime(jumatMaghrib)}
            </div>
            <div style={{ fontSize: "5vh" }}>{formatTime(startMaghrib)}</div>
          </div>
        </td>

        <td style={tdStyle}>
          <div style={isHighlighted(highlightIsha)}>
            Isha <br />
            <div style={{ fontSize: "11vh", fontWeight: "bold" }}>
              {showIsha ? formatTime(jumatIsha) : <></>}{" "}
            </div>
            <div style={{ fontSize: "5vh" }}>{formatTime(startIsha)}</div>
          </div>
        </td>
      </table>
    </>
  );
};
