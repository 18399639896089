import React, { useContext, useEffect, useState } from "react";
import { IJummaTimesProps } from "./index";
import "./Style.css";

import {
  addDays,
  addMinutes,
  formatTime,
  getPrayerTime,
  PrayerType,
} from "../Helpers";
import ApplicationContext from "../../context/ApplicationContext";

export const Jumma2: React.FC<IJummaTimesProps> = (
  props: IJummaTimesProps
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();

  const [date, setDate] = useState(new Date());
  const [Jumaah, setJumaah] = useState("");

  function setPrayerTimes() {
    const today = getPrayerTime(prayerTimes, props.dateTime.getDate());
    const tomorrow = getPrayerTime(prayerTimes, addDays(date, 1).getDate());

    if (!today || !tomorrow) {
      return;
    }

    let JumaahTimes = "";
    if (today.Jumaah2 !== undefined) {
      JumaahTimes += formatTime(today.Jumaah2);
    }

    setJumaah(JumaahTimes);
  }

  function refresh() {
    setDate(props.dateTime);
    setPrayerTimes();
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  return <>{Jumaah}</>;
};
