import React, { useEffect, useState } from "react";
import ApplicationContext, { defaults } from "./context/ApplicationContext";
import { Dashboard, IDashboardProps } from "./components/Dashboard";

function App() {
  const [date, setDate] = useState(new Date());
  const dashboardProps: IDashboardProps = {
    dateTime: date,
  };

  useEffect(() => {
    window.setTimeout(() => {
      setDate(new Date());
    }, 1000);
  }, [date]);

  return (
    <div>
      <ApplicationContext.Provider value={defaults}>
        <Dashboard {...dashboardProps} />
      </ApplicationContext.Provider>
    </div>
  );
}

export default App;
