import React, { useContext, useEffect, useState } from "react";
import { SlideType, addDays, addMinutes, getPrayerTime } from "../Helpers";
import { IPrayerStatus } from "./IPrayerStatus";
import ApplicationContext from "../../context/ApplicationContext";
import "./Style.css";

export const PrayerStatus: React.FC<IPrayerStatus> = (
  props: IPrayerStatus
): JSX.Element => {
  const context = useContext(ApplicationContext);
  const prayerTimes = context.Data.getTimes();

  const [date, setDate] = useState(props.dateTime);
  const [doZikar, setDoZikar] = useState(false);
  const [prayerStarted, setPrayerStarted] = useState(false);
  const [iftarStarted, setIftarStarted] = useState(false);
  const [isInTarawee, setIsInTarawee] = useState(false);
  const [holdDuration, setHoldDuration] = useState(new Date());

  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  function clearMessages() {
    setDoZikar(false);
    setPrayerStarted(false);
    setIftarStarted(false);
    setIsInTarawee(false);
  }

  function IsFiveMinToPrayer(prayerTime: Date): boolean {
    const time = date.getTime();

    if (
      time > addMinutes(prayerTime, -5).getTime() &&
      time < prayerTime.getTime()
    ) {
      return true;
    }
    return false;
  }

  function IsPrayerInProgress() {
    const today = getPrayerTime(prayerTimes, props.dateTime.getDate());
    const tomorrow = getPrayerTime(prayerTimes, addDays(date, 1).getDate());
    clearMessages();

    if (!today || !tomorrow) {
      return;
    }

    const prayerPause = 6;
    const totalPause = 10;

    const fajarPauseTime = addMinutes(today.JumatFajar, prayerPause);
    const zoharPauseTime = addMinutes(today.JumatZohar, prayerPause);
    const asarPauseTime = addMinutes(today.JumatAsar, prayerPause);
    const maghribPauseTime = addMinutes(today.JumatMaghrib, prayerPause);

    let ishaPauseTime = addMinutes(today.JumatIsha, prayerPause);

    if (tomorrow.IsRamadanDay) {
      ishaPauseTime = addMinutes(today.JumatIsha, 60);
    }

    const startMaghribPauseTime = addMinutes(today.StartMaghrib, 4); //TODO do we need this! and what for?

    let beforePrayer =
      IsFiveMinToPrayer(today.JumatFajar) ||
      IsFiveMinToPrayer(today.JumatZohar) ||
      IsFiveMinToPrayer(today.JumatAsar) ||
      IsFiveMinToPrayer(today.StartMaghrib) ||
      IsFiveMinToPrayer(today.JumatMaghrib) ||
      IsFiveMinToPrayer(today.JumatIsha);

    var day = days[date.getDay()];

    if (typeof today.Jumaah1 !== "undefined") {
      var isFiveMinLeft = IsFiveMinToPrayer(today.Jumaah1);

      if (isFiveMinLeft) {
        beforePrayer = true;
      }
    }
    if (typeof today.Jumaah2 !== "undefined") {
      var isFiveMinLeft = IsFiveMinToPrayer(today.Jumaah2);

      if (isFiveMinLeft) {
        beforePrayer = true;
      }
    }
    if (typeof today.Jumaah3 !== "undefined") {
      var isFiveMinLeft = IsFiveMinToPrayer(today.Jumaah3);

      if (isFiveMinLeft) {
        beforePrayer = true;
      }
    }

    if (date > today.JumatFajar && date < fajarPauseTime) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatFajar, totalPause));
      setPrayerStarted(true);
    } else if (date > today.JumatZohar && date < zoharPauseTime) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatZohar, totalPause));
      setPrayerStarted(true);
    } else if (date > today.JumatAsar && date < asarPauseTime) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatAsar, totalPause));
      setPrayerStarted(true);
    } else if (
      today.IsRamadanDay === true &&
      date > today.StartMaghrib &&
      date < startMaghribPauseTime
    ) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.StartMaghrib, 4));
      setIftarStarted(true);
    } else if (date > today.JumatMaghrib && date < maghribPauseTime) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatMaghrib, totalPause));
      setPrayerStarted(true);
    } else if (
      today.IsRamadanDay === true &&
      date > today.JumatIsha &&
      date < ishaPauseTime
    ) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatIsha, totalPause));
      setIsInTarawee(true);
    } else if (date > today.JumatIsha && date < ishaPauseTime) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.JumatIsha, totalPause));
      setPrayerStarted(true);
    } else if (
      day === "Friday" &&
      typeof today.Jumaah1 !== "undefined" &&
      date > today.Jumaah1 &&
      date < addMinutes(today.Jumaah1, 15)
    ) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.Jumaah1, 15));
      setPrayerStarted(true);
    } else if (
      day === "Friday" &&
      typeof today.Jumaah2 !== "undefined" &&
      date > today.Jumaah2 &&
      date < addMinutes(today.Jumaah2, 15)
    ) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.Jumaah2, 15));
      setPrayerStarted(true);
    } else if (
      day === "Friday" &&
      typeof today.Jumaah3 !== "undefined" &&
      date > today.Jumaah3 &&
      date < addMinutes(today.Jumaah3, 15)
    ) {
      context.Data.getSiteData().slide = SlideType.ShowPrayerInProgress;
      setHoldDuration(addMinutes(today.Jumaah3, 15));
      setPrayerStarted(true);
    } else if (date < holdDuration) {
      context.Data.getSiteData().slide = SlideType.DisplayTime;
    } else if (beforePrayer) {
      context.Data.getSiteData().slide = SlideType.DisplayTime;
    } else {
      context.Data.getSiteData().slide = SlideType.Loop;
      setDoZikar(true);
    }
  }

  useEffect(() => {
    setDate(props.dateTime);
    IsPrayerInProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateTime]);

  const infoContent = (): JSX.Element => {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: context.Data.getSettings().Slide2,
          }}
        />
      </div>
    );
  };

  const prayerInProgressContent = (): JSX.Element => {
    return (
      <div
        style={{
          fontSize: "7vw",
          color: "yellow",
          fontWeight: "bolder",
        }}
      >
        Prayer in progress
      </div>
    );
  };

  const showIftar = (): JSX.Element => {
    return (
      <div
        style={{
          fontSize: "2vw",
          color: "yellow",
          fontWeight: "bolder",
        }}
      >
        Iftar time
        <br />
        <br />
        اَللّٰهُمَّ اِنَّی لَکَ صُمْتُ وَبِکَ اٰمَنْتُ وَعَلَيْکَ تَوَکَّلْتُ
        وَعَلٰی رِزْقِکَ اَفْطَرْتُ.
        <br />
        <br />
        <br />O Allah! I fasted for you and I believe in you and I put my trust
        in You and I break my fast with your sustenance.
      </div>
    );
  };

  const inTarawee = (): JSX.Element => {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          fontSize: "3vw",
          color: "yellow",
          fontWeight: "bolder",
        }}
      >
        Taraweeh Dua
        <br />
        سُبْحَانَ ذِی الْمُلْکِ وَالْمَلَکُوْتِ ط سُبْحَانَ ذِی الْعِزَّةِ
        وَالْعَظَمَةِ وَالْهَيْبَةِ وَالْقُدْرَةِ وَالْکِبْرِيَآئِ
        وَالْجَبَرُوْتِ ط سُبْحَانَ الْمَلِکِ الْحَيِ الَّذِی لَا يَنَامُ وَلَا
        يَمُوْتُ سُبُّوحٌ قُدُّوْسٌ رَبُّنَا وَرَبُّ الْمَلَائِکَةِ وَالرُّوْحِ
        ط اَللّٰهُمَّ اَجِرْنَا مِنَ النَّارِ يَا مُجِيْرُ يَا مُجِيْرُ يَا
        مُجِيْر۔
      </div>
    );
  };
  return (
    <div>
      {doZikar ? infoContent() : <></>}
      {prayerStarted ? prayerInProgressContent() : <></>}
      {iftarStarted ? showIftar() : <></>}
      {isInTarawee ? inTarawee() : <></>}
    </div>
  );
};
